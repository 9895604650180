import React from "react";
import { Button } from "semantic-ui-react";
import signout from "../../services/signOut";

import "./unauthorized.css";

const Unauthorized = () => {
  return (
    <div className="unauthorized">
      <div className="image">
        <img
          src="https://img.sheroes.in/img/uploads/sheroespage/logoes/1542368945og_image.png"
          height="300px"
          alt="k"
        />
      </div>
      <div className="text">You Don't have access to view this page!</div>
      <br />
      <div className="text">
        <Button color="black" onClick={signout}>
          Sign-Out
        </Button>
      </div>
    </div>
  );
};

export default Unauthorized;
