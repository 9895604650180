// module imports
import React, { useState, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";

// services imports
import { breakdownStream } from "../../../utils/configOps";
import {
  fetchConfig,
  fetchConfigMeta,
  fetchConfigMetaLocal,
  fetchSetOrderGlobalStats
} from "../../../services/config";

// components imports
import { Loader } from "semantic-ui-react";
import PlaceholderImage from "./PlaceholderImage";
import DnD from "./DnD";
import SelectStreamMenu from "./SelectStreamMenu";
import useSyncState from "../../../hooks/useSyncState";

const SetOrder = () => {
  const [loading, toggleLoading] = useState(true);

  const selectedStream = useStoreState(
    state => state.currentDnd.selectedStream
  );
  const feedConfig = useStoreState(state => state.feedConfig);
  const streamMeta = useStoreState(state => state.streamMeta);

  const setSelectedStream = useStoreActions(action => action.setSelectedStream);
  const setRows = useStoreActions(action => action.setRows);
  const setStreams = useStoreActions(action => action.setStreams);
  const setRowOrder = useStoreActions(action => action.setRowOrder);

  const setFeedConfig = useStoreActions(action => action.setFeedConfig);
  const setStreamMeta = useStoreActions(action => action.setStreamMeta);
  const setStreamGlobalStats = useStoreActions(
    action => action.setStreamGlobalStats
  );
  const setStreamMetaLocal = useStoreActions(
    action => action.setStreamMetaLocal
  );

  useSyncState(feedConfig.config_version);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchConfig();
      setFeedConfig(res.data.configuration);
      toggleLoading(false);
    };

    const fetchMetaData = async () => {
      const res = await fetchConfigMeta();
      setStreamMeta(res.data.query_result.data.rows);
    };

    const fetchMetaDataLocal = async () => {
      const res = await fetchConfigMetaLocal();
      setStreamMetaLocal(res.data.query_result.data.rows);
    };

    const fetchGlobalStatsForSetOrder = async () => {
      const res = await fetchSetOrderGlobalStats();
      setStreamGlobalStats(res.data.query_result.data.rows);
    };

    if (Object.keys(feedConfig).length === 0) {
      fetchData();
    } else {
      toggleLoading(false);
    }

    if (Object.keys(streamMeta).length === 0) {
      fetchMetaData();
      fetchMetaDataLocal();
      fetchGlobalStatsForSetOrder();
    } else {
      toggleLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const handleSelectStream = stream => {
    setSelectedStream(stream);
    const [rows, streams, rowOrder] = breakdownStream(
      feedConfig.config_data,
      stream
    );
    setRows(rows);
    setStreams(streams);
    setRowOrder(rowOrder);
  };

  if (!loading) {
    return (
      <div className="set-order-body">
        <SelectStreamMenu selectStream={handleSelectStream} />

        <div className="dnd-body">
          {selectedStream ? <DnD /> : <PlaceholderImage />}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Loader inline active />
        <PlaceholderImage />
      </div>
    );
  }
};

export default React.memo(SetOrder);
