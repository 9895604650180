import http from "./httpService";
import APIURL from "../utils/apiUrl";

async function fetchUserDetails(token) {
  http.setUserToken(token);
  return await http.post(APIURL.USER_DETAIL_URL).then(res => {
    const data = {
      token: res.data.token,
      userId: res.data.user_summary.userid,
      profileImage: res.data.user_summary.photo_url,
      emailId: res.data.user_summary.email_id,
      firstName: res.data.user_summary.first_name,
      lastName: res.data.user_summary.last_name
    };
    return data;
  });
}

export default fetchUserDetails;
