import { useStoreState } from "easy-peasy";

function useCategoryFilter() {
  const streams = useStoreState(state => state.feedConfig.config_data.streams);
  const allFilters = useStoreState(state => state.filters);
  const categoryFilteredStreams = Object.keys(streams)
    .map(streamName => {
      const category = streams[streamName].categories || ["default"];

      if (allFilters.categories.length === 0) {
        return streamName;
      }

      const inFilter = allFilters.categories.filter(
        setCategory => setCategory === category[0]
      );

      if (inFilter.length !== 0) {
        return streamName;
      }

      return null;
    })
    .filter(categoryFilteredStreamName => categoryFilteredStreamName);

  return categoryFilteredStreams;
}

export default useCategoryFilter;
