// module exports
import React, { useState, useEffect } from "react";
import shortid from "shortid";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useStoreActions, useStoreState } from "easy-peasy";

// component exports
import Row from "./Row";
import { Loader, Icon } from "semantic-ui-react";

shortid.characters(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@"
);

const DnD = () => {
  const [loading, toggleLoading] = useState(true);

  const rows = useStoreState(state => state.currentDnd.rows);
  const streams = useStoreState(state => state.currentDnd.streams);
  const rowOrder = useStoreState(state => state.currentDnd.rowOrder);

  const setRows = useStoreActions(action => action.setRows);
  const setStreams = useStoreActions(action => action.setStreams);
  const setRowOrder = useStoreActions(action => action.setRowOrder);

  const setDirty = useStoreActions(action => action.setDirty);

  useEffect(() => {
    toggleLoading(false);
  }, [rows]);

  const onDragEnd = result => {
    setDirty(true);
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === "delete") {
      const newStreams = { ...streams };
      delete newStreams[draggableId];

      const newRows = { ...rows };
      newRows[source.droppableId].streamIds.splice(source.index, 1);
      setStreams(newStreams);
      setRows(newRows);

      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = rows[source.droppableId];
    const finish = rows[destination.droppableId];

    if (start === finish) {
      const newStreamIds = Array.from(start.streamIds);
      newStreamIds.splice(source.index, 1);
      newStreamIds.splice(destination.index, 0, draggableId);

      const newRow = {
        ...start,
        streamIds: newStreamIds
      };

      setRows({ ...rows, [newRow.id]: newRow });
      return;
    }

    const startStreamIds = Array.from(start.streamIds);
    startStreamIds.splice(source.index, 1);
    const newStart = {
      ...start,
      streamIds: startStreamIds
    };

    const finishStreamIds = Array.from(finish.streamIds);
    finishStreamIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      streamIds: finishStreamIds
    };

    setRows({ ...rows, [newStart.id]: newStart, [newFinish.id]: newFinish });
    return;
  };

  const addNewRow = atIndex => {
    setDirty(true);
    const allRows = Object.keys(rows);
    const size = allRows.length + 1;

    setRows({
      ...rows,
      [`row-${size}`]: { id: `row-${size}`, title: "", streamIds: [] }
    });
    const rowAdded = [...rowOrder];
    rowAdded.splice(atIndex + 1, 0, `row-${size}`);
    setRowOrder(rowAdded);
  };

  const handleAddNewStream = (rowId, content) => {
    setDirty(true);
    const allRows = rows;
    const newStreamId = rowId.replace(/\D+/g, "") + "-" + shortid.generate();

    allRows[rowId]["streamIds"].push(newStreamId);

    setStreams({
      ...streams,
      [newStreamId]: { id: newStreamId, content: content }
    });
    setRows(allRows);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {loading ? (
        <div>
          <Loader active size="tiny" inline />
          <span style={{ marginLeft: "5px" }}>Fetching</span>
        </div>
      ) : null}
      <div className="drop-container">
        <div className="drop-left">
          {rowOrder.map((rowId, index) => {
            const row = rows[rowId];
            const allStreamsForThis = row.streamIds.map(
              streamId => streams[streamId]
            );
            return (
              <Row
                row={row}
                key={row.id}
                streams={allStreamsForThis}
                index={index}
                addNewStream={handleAddNewStream}
                addNewRow={addNewRow}
              />
            );
          })}
          <br />
        </div>
        <div className="drop-right">
          <Droppable droppableId={"delete"} direction="horizontal">
            {(provided, snapshot) => (
              <div
                className="deleteRow"
                style={{ background: snapshot.isDraggingOver ? "#ffcdd2" : "" }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Icon name="trash" />
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
};

export default DnD;
