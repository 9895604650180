import React from "react";
import { useStoreState } from "easy-peasy";
import getNumeral from "../../../utils/getNumeral";

const SetOrderGlobal = props => {
  const globalStats = useStoreState(state => state.streamGlobalStats);
  const selectedStream = useStoreState(
    state => state.currentDnd.selectedStream
  );

  const data = globalStats.filter(item => {
    return item.setOrderKey === selectedStream;
  })[0];

  const CTR_percent = data ? data.CTR_percent : "NA";
  const clicks = data ? data.clicks : "NA";
  const impr_per_post = data ? data.impr_per_post : "NA";
  const impressions = data ? data.impressions : "NA";
  const unique_posts = data ? data.unique_posts : "NA";
  const unique_users = data ? data.unique_users : "NA";

  return (
    <div className="card-my">
      <div className="global-stats-matrix">
        <div className="global-stats-matrix-cell">
          <div className="data">{getNumeral(unique_users)}</div>
          <div className="title">USERS</div>
        </div>
        <div className="global-stats-matrix-cell">
          <div className="data">{getNumeral(unique_posts)}</div>
          <div className="title">POSTS</div>
        </div>
        <div className="global-stats-matrix-cell">
          <div className="data">{getNumeral(impressions)}</div>
          <div className="title">IMPR</div>
        </div>
        <div className="global-stats-matrix-cell">
          <div className="data">{getNumeral(impr_per_post)}</div>
          <div className="title">IMPR/POST</div>
        </div>
        <div className="global-stats-matrix-cell">
          <div className="data">{getNumeral(clicks)}</div>
          <div className="title">CLICKS</div>
        </div>
        <div className="global-stats-matrix-cell">
          <div className="data" style={{ color: "#15AB39" }}>
            {getNumeral(CTR_percent)}%
          </div>
          <div className="title">CTR %</div>
        </div>
      </div>
    </div>
  );
};

export default SetOrderGlobal;
