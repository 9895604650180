const categories = [
  ["engaging", "#6bba29"],
  ["unengaging", "#DC4641"],
  ["promotional", "#F9C22E"],
  ["default", "#2793E7"]
];

const platforms = ["android", "iOS", "web"];

export { categories, platforms };
