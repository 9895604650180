import React from "react";

const PlaceholderImage = () => {
  return (
    <div className="placeholder-div">
      <img
        src="https://assets-ouch.icons8.com/preview/819/a75c989b-ea17-456d-9e9a-a6a2d07c8428.png"
        height="300px"
        alt="supposed to be JPEG"
      />
      <br />
      <p>Select Set Order to Get started</p>
    </div>
  );
};

export default PlaceholderImage;
