import React from "react";
import { Icon } from "semantic-ui-react";

import "./infoMain.css";
import SelectCategory from "./FilterComponents/selectCategory";
import JSONrender from "./FilterComponents/showJSON";

const Filters = props => {
  return (
    <div className="filter-body">
      <br />
      <h4>
        <Icon name="filter" />
        Filters
      </h4>
      <SelectCategory />
      <h4>
        <Icon name="code" />
        JSON View
      </h4>
      <JSONrender />
    </div>
  );
};

export default Filters;
