// module imports
import React, { useState } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import swal from "sweetalert";

// services import
import { commitConfig, fetchConfig } from "../../../services/config";

// components import
import { Button, Icon, Modal, Form, Popup } from "semantic-ui-react";
import { generateConfigForAStream } from "../../../utils/configOps";

const CommitBox = () => {
  const [commitMessage, setCommitMessage] = useState("");

  const [confirmLoading, setConfirmLoading] = useState(false);

  const user = useStoreState(state => state.user);
  const feedConfig = useStoreState(state => state.feedConfig);

  const dndData = useStoreState(state => state.currentDnd);

  const setDirty = useStoreActions(action => action.setDirty);

  const newConfig = { ...feedConfig };
  const generateJson = () => {
    const streamOrder = generateConfigForAStream(
      dndData.rows,
      dndData.streams,
      dndData.rowOrder
    );

    delete newConfig.config_data.set_orders[dndData.selectedStream]
      .stream_order;

    newConfig.config_data.set_orders[
      dndData.selectedStream
    ].stream_order = streamOrder;
    setDirty(false);
  };

  const copyJSON = () => {
    navigator.clipboard.writeText(
      JSON.stringify(newConfig.config_data, null, 2)
    );
  };

  const captureText = e => {
    setCommitMessage(e.target.value);
  };

  async function commit() {
    if (commitMessage !== "") {
      setConfirmLoading(true);

      const latestConfig = await fetchConfig();
      const latestVersion = latestConfig.data.configuration["config_version"];

      if (newConfig["config_version"] === latestVersion) {
        await commitConfig(newConfig.config_data, commitMessage + " [LUMOS]");
        setConfirmLoading(false);
        swal("Committed", `${commitMessage}`, "success").then(data => {
          window.location.reload();
        });
      } else {
        swal(
          "Version Mismatch!",
          `RemoteConfig was updated before this commit. \n Please re-add your changes to the latest version.`,
          "error"
        ).then(data => {
          window.location.reload();
        });
      }
    } else {
      alert("commit message cannot be empty");
    }
  }

  return (
    <Modal
      trigger={
        <Button
          onClick={generateJson}
          disabled={dndData.selectedStream === ""}
          color="green"
        >
          Commit
        </Button>
      }
      closeIcon
    >
      <Modal.Header>Commit Current Changes</Modal.Header>
      <Modal.Content>
        <label>
          User : <strong>{user["emailId"]}</strong>
        </label>
        <br />
        <label>
          Last Version : #<strong>{feedConfig.config_version}</strong>
        </label>
        <br />
        <label>
          Last Commit Message :{" "}
          <strong>{feedConfig.config_commit_message}</strong>
        </label>
        <br />
        <br />
        <Form>
          <Form.TextArea
            label="Enter Commit Message"
            onChange={captureText}
          ></Form.TextArea>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Popup
          content="copied to clipboard"
          on="click"
          trigger={
            <Button onClick={copyJSON}>
              <Icon name="copy" /> Copy JSON
            </Button>
          }
        />
        <Button
          color="green"
          loading={confirmLoading}
          disabled={confirmLoading}
          onClick={commit}
        >
          <Icon name="checkmark" />
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CommitBox;
