import { action } from "easy-peasy";

export default {
  authorized: false,
  user: {},
  feedConfig: {},
  currentDnd: {
    selectedStream: "",
    rows: {},
    streams: {},
    rowOrder: []
  },
  isDirty: false,
  streamMeta: [],
  streamMetaLocal: [],
  streamGlobalStats: [],
  filters: {
    categories: [],
    platforms: []
  },
  clickedStream: "",

  // Actions
  setFeedConfig: action((state, config) => {
    state.feedConfig = config;
  }),
  setUser: action((state, user) => {
    state.user = user;
  }),
  setSelectedStream: action((state, selectedStream) => {
    state.currentDnd.selectedStream = selectedStream;
  }),
  setRows: action((state, rows) => {
    state.currentDnd.rows = rows;
  }),
  setStreams: action((state, streams) => {
    state.currentDnd.streams = streams;
  }),
  setRowOrder: action((state, rowOrder) => {
    state.currentDnd.rowOrder = rowOrder;
  }),
  setDirty: action((state, dirty) => {
    state.isDirty = dirty;
  }),
  setStreamMeta: action((state, data) => {
    state.streamMeta = data;
  }),
  setStreamMetaLocal: action((state, data) => {
    state.streamMetaLocal = data;
  }),
  setStreamGlobalStats: action((state, data) => {
    state.streamGlobalStats = data;
  }),
  setFilters: action((state, filters) => {
    state.filters = filters;
  }),
  setClickedStream: action((state, clickedStream) => {
    state.clickedStream = clickedStream;
  })
};
