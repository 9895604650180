import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useStoreState } from "easy-peasy";
import { onlyUnique } from "../../../utils/rowOps";
import "chart.piecelabel.js";

const DoughnutCategory = () => {
  const Dnd = useStoreState(state => state.currentDnd);
  const streams = useStoreState(state => state.feedConfig.config_data.streams);
  const AllLocalMetaData = useStoreState(state => state.streamMetaLocal);
  const selectedStream = useStoreState(
    state => state.currentDnd.selectedStream
  );

  // const firstSlotCards = getTopNCardsAtAPosition(Dnd, 0, Dnd.rowOrder.length);
  // const allCurrentStreams = firstSlotCards.map(item => item[0]);

  const allCurrentStreams = Object.values(Dnd.streams)
    .map(item => item.content)
    .filter(onlyUnique);

  const promotional = allCurrentStreams.filter(item => {
    const category = streams[item].categories || ["default"];
    return category[0] === "promotional";
  });

  let sumPromotional = 0;
  promotional.forEach(element => {
    const dataPoint = AllLocalMetaData.filter(
      item => item.category === element && item.setOrderKey === selectedStream
    );
    if (dataPoint.length !== 0) {
      if (dataPoint[0].impressions) {
        sumPromotional += dataPoint[0].impressions;
      }
    }
  });

  const engaging = allCurrentStreams.filter(item => {
    const category = streams[item].categories || ["default"];
    return category[0] === "engaging";
  });
  let sumEngaging = 0;
  engaging.forEach(element => {
    const dataPoint = AllLocalMetaData.filter(
      item => item.category === element && item.setOrderKey === selectedStream
    );
    if (dataPoint.length !== 0) {
      if (dataPoint[0].impressions) {
        sumEngaging += dataPoint[0].impressions;
      }
    }
  });

  const unengaging = allCurrentStreams.filter(item => {
    const category = streams[item].categories || ["default"];
    return category[0] === "unengaging";
  });
  let sumUnengaging = 0;
  unengaging.forEach(element => {
    const dataPoint = AllLocalMetaData.filter(
      item => item.category === element && item.setOrderKey === selectedStream
    );
    if (dataPoint.length !== 0) {
      if (dataPoint[0].impressions) {
        sumUnengaging += dataPoint[0].impressions;
      }
    }
  });

  const defaultC = allCurrentStreams.filter(item => {
    const category = streams[item].categories || ["default"];
    return category[0] === "default";
  });
  let sumdefaultC = 0;
  defaultC.forEach(element => {
    const dataPoint = AllLocalMetaData.filter(
      item => item.category === element && item.setOrderKey === selectedStream
    );
    if (dataPoint.length !== 0) {
      if (dataPoint[0].impressions) {
        sumdefaultC += dataPoint[0].impressions;
      }
    }
  });

  const sum = sumEngaging + sumPromotional + sumUnengaging + sumdefaultC;
  const data = {
    labels: ["Engaging", "Promotional", "Unengaging", "default"],
    datasets: [
      {
        data: [
          ((sumEngaging / sum) * 100).toFixed(2),
          ((sumPromotional / sum) * 100).toFixed(2),
          ((sumUnengaging / sum) * 100).toFixed(2),
          ((sumdefaultC / sum) * 100).toFixed(2)
        ],
        backgroundColor: ["#6bba29", "#F9C22E", "#DC4641", "#2793E7"],
        hoverBackgroundColor: ["#6bba29", "#F9C22E", "#DC4641", "#2793E7"]
      }
    ],
    text: "23%"
  };

  return (
    <div className="card-my">
      <p>CATEGORY RATIO BY IMPRESSIONS</p>
      <Doughnut
        data={data}
        width={400}
        height={400}
        options={{
          fontSize: "5px",
          legend: {
            position: "bottom"
          },
          pieceLabel: {
            render: "value",
            fontColor: "#fff"
          }
        }}
      />
    </div>
  );
};

export default DoughnutCategory;
