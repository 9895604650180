// module imports
import React from "react";

function FourOFour() {
  return (
    <div className="placeholder-div">
      <img
        src="https://www.trzcacak.rs/myfile/full/375-3750740_404-illustration.png"
        height="300px"
        alt="supposed to be JPEG"
      />
      <br />
      <p>Oops! 404 Page Not Found</p>
    </div>
  );
}

export default FourOFour;
