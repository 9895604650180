import { useState } from "react";

import { fetchConfig } from "../services/config";
import useInterval from "./useInterval";

import swal from "sweetalert";

function useSyncState(config_version) {
  const [isSyncRunning, setIsSyncRunning] = useState(true);

  const checkSyncState = async () => {
    const latestConfig = await fetchConfig();
    const latestVersion = latestConfig.data.configuration["config_version"];

    if (latestVersion !== config_version) {
      setIsSyncRunning(false);
      swal(
        "Version Mismatch",
        `Your config version is outdated as someone committed a newer version.\n\n The app will reload!`,
        "warning"
      ).then(data => {
        window.location.reload();
      });
    }
  };

  useInterval(
    () => {
      checkSyncState();
    },
    isSyncRunning ? 60000 : null
  );
}

export default useSyncState;
