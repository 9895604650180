export default {
  USER_DETAIL_URL: "/participant/user/getUserSummarry",
  REMOTE_CONFIG_URL: "/participant/remote_config/?config_type=ServerFeedConfig",
  REMOTE_CONFIG_COMMIT_URL: "/participant/remote_config/commit",
  REMOTE_CONFIG_META_URL:
    "https://dashboard.sheroes.com/api/queries/3930/results.json?api_key=5ZVVnRPFktGkW0IxYiNknWZED6F06Pq7ZanFbJia",
  REMOTE_CONFIG_LOCAL_META_URL:
    "https://dashboard.sheroes.com/api/queries/3974/results.json?api_key=4nA0Ne0AeS78jLVMAs9oonjC31by7zZd67fcEAvM",
  REMOTE_CONFIG_SETORDER_GLOBAL:
    "https://dashboard.sheroes.com/api/queries/3972/results.json?api_key=FIJiZ8kfkQcqF8dHUo2Dnww1ZgyEYbUZidVGI0L7"
};
