const getTopNCardsAtAPosition = (Dnd, position, topN) => {
  const allStreamIdsAtPosition = Object.values(Dnd.rows).map(
    item => item.streamIds[position]
  );

  const allStreamsAtPosition = allStreamIdsAtPosition
    .map(id => Dnd.streams[id])
    .filter(element => element);

  //count streams
  let countMap = {};
  allStreamsAtPosition.forEach(element => {
    countMap[element.content] = (countMap[element.content] || 0) + 1;
  });

  // Sort Object
  var sortable = [];
  for (var item in countMap) {
    sortable.push([item, countMap[item]]);
  }

  const sorted = sortable.sort(function(a, b) {
    return b[1] - a[1];
  });

  // Return top-N items
  return sorted.slice(0, topN);
};

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

const getUniqueNum = streams => {
  return Object.values(streams)
    .map(item => item.content)
    .filter(onlyUnique).length;
};

export { getUniqueNum, getTopNCardsAtAPosition, onlyUnique };
