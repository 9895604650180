import React from "react";
import { useStoreState } from "easy-peasy";
import { getUniqueNum } from "../../../utils/rowOps";

const MetricsOverallSize = () => {
  const selectedData = useStoreState(state => state.currentDnd);

  const totalCards = Object.keys(selectedData.streams).length;
  const depth = selectedData.rowOrder.length;
  const uniqueCards = getUniqueNum(selectedData.streams);

  const matrixData = [
    { title: "Total Cards", data: totalCards },
    { title: "Unique Cards", data: uniqueCards },
    { title: "Depth", data: depth }
  ];

  return (
    <div className="card-my">
      <p>OVERALL SIZE</p>
      <div className="overall-size-matrix">
        {matrixData.map((item, index) => {
          return (
            <div className="overall-size-matrix-cell" key={index}>
              <div className="data">{item.data}</div>
              <div className="title">{item.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MetricsOverallSize;
