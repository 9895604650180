import React from "react";
import { Card, Icon, Label } from "semantic-ui-react";

import { categories } from "../../../utils/legendValues";
import StreamCardMetrics from "./StreamCardMetrics";
import { useStoreActions } from "easy-peasy";

const StreamCard = props => {
  const setClickedStream = useStoreActions(action => action.setClickedStream);

  const getColor = name => {
    var color = categories.filter(item => {
      return item[0] === name.toLowerCase();
    });
    return color[0][1];
  };

  return (
    <Card
      style={{
        borderLeft: "20px solid " + getColor(props.StreamCategory),
        opacity: props.meta.enabled ? 1 : 0.2
      }}
    >
      <Card.Content>
        <Card.Description>
          {props.meta.supportedSources.map((item, index) => {
            if (item === "android") {
              return (
                <Icon
                  size="large"
                  name="android"
                  style={{ color: "#79C258" }}
                  key={index}
                />
              );
            } else if (item === "web") {
              return (
                <Icon
                  size="large"
                  name="chrome"
                  style={{ color: "#0078d7" }}
                  key={index}
                />
              );
            } else if (item === "ios") {
              return <Icon size="large" name="apple" key={index} />;
            } else {
              return null;
            }
          })}
          <Label
            attached="top right"
            style={{ cursor: "pointer" }}
            onClick={() => setClickedStream(props.name)}
          >
            <Icon name="eye" />
            META
          </Label>
        </Card.Description>
        <br />
        <Card.Header className="stream-name">{props.name}</Card.Header>
        <Card.Meta>
          <Icon name="fork" size="small" />
          {props.meta.streamType}
        </Card.Meta>
      </Card.Content>
      <Card.Content extra>
        {/* <div className="card-icon edit">
            <Icon name="pencil" />
            Edit
          </div>
          <div className="card-icon fork">
            <Icon name="fork" />
            Fork
          </div>
          <div className="card-icon delete" onClick={showWarning}>
            <Icon name="trash" />
            Delete
          </div> */}
        <div className="meta">
          <StreamCardMetrics streamTitle={props.name} />
        </div>
      </Card.Content>
    </Card>
  );
};

export default StreamCard;
