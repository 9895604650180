import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { useStoreState, useStoreActions } from "easy-peasy";
import getNumeral from "../../../utils/getNumeral";
import { categories } from "../../../utils/legendValues";
import { Icon } from "semantic-ui-react";
import useCategoryFilter from "../../../hooks/useCategoryFilter";
import useSearch from "../../../hooks/useSearch";

const StreamTabular = props => {
  const AllMetaData = useStoreState(state => state.streamMeta);
  const streams = useStoreState(state => state.feedConfig.config_data.streams);
  const categoryFilteredStreams = useCategoryFilter();
  const searched = useSearch(categoryFilteredStreams, props.search);

  const setClickedStream = useStoreActions(action => action.setClickedStream);

  const final = searched.map(streamName => {
    const metaData = AllMetaData.filter(metadataItem => {
      return metadataItem.category === streamName;
    });

    if (metaData.length === 0) {
      return {
        category: streamName,
        CTR_percent: null,
        clicks: null,
        impressions: null,
        avg_impr_time: null,
        unique_posts: null,
        unique_users: null
      };
    }

    return metaData[0];
  });

  const getColor = name => {
    var color = categories.filter(item => {
      return item[0] === name.toLowerCase();
    });
    return color[0][1];
  };

  return (
    <div className="react-table">
      <p>*Based on data collected in last 7 days (Android Users)</p>
      <ReactTable
        data={final}
        columns={[
          {
            Header: "Stream Name",
            accessor: "category",
            width: 300,
            className: "a-cell",
            headerClassName: "a-cell-header",
            Cell: props => (
              <div
                onClick={() => setClickedStream(props.value)}
                style={{ cursor: "pointer" }}
              >
                <Icon name="eye" />
                {props.value}
              </div>
            )
          },
          {
            Header: "USERS",
            accessor: "unique_users",
            className: "a-cell-data",
            Cell: props =>
              props.value ? (
                getNumeral(props.value)
              ) : (
                <Icon name="ban" style={{ opacity: 0.2 }} />
              ),
            headerClassName: "a-cell-header"
          },
          {
            Header: "POSTS",
            accessor: "unique_posts",
            className: "a-cell-data",
            Cell: props =>
              props.value ? (
                getNumeral(props.value)
              ) : (
                <Icon name="ban" style={{ opacity: 0.2 }} />
              ),
            headerClassName: "a-cell-header"
          },
          {
            Header: "IMPRESSIONS",
            accessor: "impressions",
            className: "a-cell-data",
            Cell: props =>
              props.value ? (
                getNumeral(props.value)
              ) : (
                <Icon name="ban" style={{ opacity: 0.2 }} />
              ),
            headerClassName: "a-cell-header"
          },
          {
            Header: "AVG VIEW TIME",
            accessor: "avg_impr_time",
            className: "a-cell-data",
            Cell: props =>
              props.value ? (
                `${getNumeral(props.value)}s`
              ) : (
                <Icon name="ban" style={{ opacity: 0.2 }} />
              ),
            headerClassName: "a-cell-header"
          },
          {
            Header: "CLICKS",
            accessor: "clicks",
            className: "a-cell-data",
            Cell: props =>
              props.value ? (
                getNumeral(props.value)
              ) : (
                <Icon name="ban" style={{ opacity: 0.2 }} />
              ),
            headerClassName: "a-cell-header"
          },
          {
            Header: "CTR %",
            accessor: "CTR_percent",
            className: "a-cell-data",
            Cell: props =>
              props.value ? (
                `${getNumeral(props.value)}%`
              ) : (
                <Icon name="ban" style={{ opacity: 0.2 }} />
              ),
            headerClassName: "a-cell-header"
          }
        ]}
        defaultSorted={[
          {
            id: "CTR_percent",
            desc: true
          }
        ]}
        pageSize={final.length}
        showPagination={false}
        className="-striped -highlight overall-table"
        resizable={false}
        style={{
          maxHeight: "80vh"
        }}
        getTrProps={(state, rowInfo, column) => {
          const name = rowInfo.row["category"];
          let category = "default";
          if (streams[name]) {
            if (streams[name].categories) {
              category = streams[name].categories[0];
            }
          }
          const color = getColor(category);

          return {
            style: {
              borderLeft: `15px solid ${color}`
            }
          };
        }}
      />
    </div>
  );
};

export default StreamTabular;
