// module imports
import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";

// services and hooks import
import { fetchConfig, fetchConfigMeta } from "../../../services/config";
import { useStoreState, useStoreActions } from "easy-peasy";

// component imports
import { Form, Loader } from "semantic-ui-react";
import StreamCardGroup from "./StreamCardGroup";
import Legend from "../Legend";
import SearchBar from "./Search";
import PlaceholderImage from "../setorder/PlaceholderImage";
import NavTabs from "../navTabs";
import StreamTabular from "./StreamTabular";
import ViewSwitch from "./ViewSwitch";

const StreamStudio = () => {
  const [searchString, setSearchString] = useState("");
  const [loading, toggleLoading] = useState(true);

  const feedConfig = useStoreState(state => state.feedConfig);
  const setFeedConfig = useStoreActions(action => action.setFeedConfig);

  const streamMeta = useStoreState(state => state.streamMeta);
  const setStreamMeta = useStoreActions(action => action.setStreamMeta);

  const handleSearch = value => {
    const queryParam = new URL(document.location).searchParams.get("q");
    if (value !== "") {
      setSearchString(value);
    } else if (queryParam) {
      setSearchString(queryParam);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchConfig();
      setFeedConfig(res.data.configuration);
      toggleLoading(false);
    };

    const fetchMetaData = async () => {
      const res = await fetchConfigMeta();
      setStreamMeta(res.data.query_result.data.rows);
    };

    if (Object.keys(feedConfig).length === 0) {
      fetchData();
    } else {
      toggleLoading(false);
    }

    if (Object.keys(streamMeta).length === 0) {
      fetchMetaData();
    } else {
      toggleLoading(false);
    }

    // eslint-disable-next-line
  }, []);

  if (!loading) {
    return (
      <div className="studio-body">
        <Form>
          <Form.Group>
            <Form.Field>
              <NavTabs />
            </Form.Field>
            <Form.Field>
              <SearchBar getSearch={handleSearch} />
            </Form.Field>
            <Form.Field>
              <div style={{ display: "flex" }}>
                <ViewSwitch />
                <Legend />
              </div>
            </Form.Field>
          </Form.Group>
        </Form>

        <br />

        <Route path="/studio/grid">
          <div className="cards">
            <StreamCardGroup search={searchString} />
          </div>
        </Route>

        <Route path="/studio/table">
          <div className="cards">
            <StreamTabular search={searchString} />
          </div>
        </Route>
      </div>
    );
  } else {
    return (
      <div>
        <Loader inline active />
        <PlaceholderImage />
      </div>
    );
  }
};

export default StreamStudio;
