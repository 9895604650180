import http from "./httpService";
import APIURL from "../utils/apiUrl";

async function commitConfig(config_data, message) {
  const data = await http.post(APIURL.REMOTE_CONFIG_COMMIT_URL, {
    config_type: "ServerFeedConfig",
    config_commit_message: message,
    config_data: config_data
  });

  return data;
}

async function fetchConfig() {
  const res = http.get(APIURL.REMOTE_CONFIG_URL);
  return res;
}

async function fetchConfigMeta() {
  const res = http.get(APIURL.REMOTE_CONFIG_META_URL);
  return res;
}

async function fetchConfigMetaLocal() {
  const res = http.get(APIURL.REMOTE_CONFIG_LOCAL_META_URL);
  return res;
}

async function fetchSetOrderGlobalStats() {
  const res = http.get(APIURL.REMOTE_CONFIG_SETORDER_GLOBAL);
  return res;
}

export {
  commitConfig,
  fetchConfig,
  fetchConfigMeta,
  fetchConfigMetaLocal,
  fetchSetOrderGlobalStats
};
