import React, { useState, useEffect } from "react";
import { Popup, Button } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";

const ViewSwitch = () => {
  const [nextRoute, setNextRoute] = useState("");
  const [currentIcon, setCurrentIcon] = useState("");

  const currentLocation = useLocation();

  useEffect(() => {
    if (currentLocation.pathname === "/studio/table") {
      setNextRoute("/studio/grid");
      setCurrentIcon("th list");
    } else if (currentLocation.pathname === "/studio/grid") {
      setNextRoute("/studio/table");
      setCurrentIcon("grid layout");
    }
  }, [currentLocation]);

  return (
    <Link to={nextRoute}>
      <Popup
        content={"change view"}
        trigger={<Button icon={currentIcon} color="instagram" />}
      />
    </Link>
  );
};

export default ViewSwitch;
