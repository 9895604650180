import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavTabs = props => {
  const location = useLocation();

  return (
    <div className="nav-tabs">
      <Link to="/setorder" style={{ color: "black" }}>
        <label
          style={{
            fontSize: "12px",
            cursor: "pointer",
            opacity: location.pathname.startsWith("/setorder") ? 1 : 0.2
          }}
        >
          SET ORDER
        </label>
      </Link>
      <br />
      <Link to="/studio/table" style={{ color: "black" }}>
        <label
          style={{
            fontSize: "12px",
            cursor: "pointer",
            opacity: location.pathname.startsWith("/studio") ? 1 : 0.2
          }}
        >
          STREAM STUDIO
        </label>
      </Link>
    </div>
  );
};

export default NavTabs;
