import React from "react";
import { useStoreState } from "easy-peasy";
import MetricsOverallSize from "./MetricsOverallSize";
import DoughnutCategory from "./doughnutCategory";

import "./infoMain.css";

import MetricsStreamCount from "./MetricsStreamCount";
import SetOrderGlobal from "./setOrderGlobal";
import { Icon } from "semantic-ui-react";

const InfoMain = () => {
  const selectedStream = useStoreState(
    state => state.currentDnd.selectedStream
  );

  return (
    <div className="infomain-body">
      <span>
        <h4>
          <Icon name="dna" />
          <strong>METRICS:</strong> {selectedStream}
        </h4>
      </span>
      {selectedStream ? (
        <React.Fragment>
          <SetOrderGlobal />
          <MetricsOverallSize />
          <DoughnutCategory />
          <MetricsStreamCount />
        </React.Fragment>
      ) : (
        <div style={{ opacity: 0.5 }}>Select a Set-Order to view metrics</div>
      )}
    </div>
  );
};

export default InfoMain;
