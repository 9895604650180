// module imports
import React from "react";
import { useStoreState } from "easy-peasy";

// services import
import signout from "../../services/signOut";

// components import
import { Popup, Button } from "semantic-ui-react";
import SidebarMenu from "./SidebarMenu";

const UserInfo = () => {
  const user = useStoreState(state => state.user);

  return (
    <div className="auth-component">
      <div className="auth-username">
        <strong>
          {user.firstName.toUpperCase()} {user.lastName.toUpperCase()}
        </strong>
        <div>{user.emailId}</div>
      </div>
      <Popup
        trigger={
          <div
            className="auth-avatar"
            style={{ background: "url(" + user.profileImage + ")" }}
          ></div>
        }
        content={<Button size="small" content="Sign-Out" onClick={signout} />}
        position="bottom right"
        on="click"
      />
      <div className="auth-menu">
        <SidebarMenu />
      </div>
    </div>
  );
};

export default UserInfo;
