import React from "react";
import { useStoreState } from "easy-peasy";
import { getTopNCardsAtAPosition } from "../../../utils/rowOps";

const MetricsStreamCount = () => {
  const selectedData = useStoreState(state => state.currentDnd);

  const top5at1 = getTopNCardsAtAPosition(selectedData, 0, 5);
  const top3at2 = getTopNCardsAtAPosition(selectedData, 1, 3);

  return (
    <div className="card-my">
      <p>POSITIONAL COUNT</p>
      <div className="count-metrics">
        <p className="subtitle">PRIMARY SLOT</p>

        {top5at1.map((item, index) => {
          return (
            <div className="count-metrics-cell" key={index}>
              <div className="title">{item[0]}</div>
              <div className="data">{item[1]}</div>
            </div>
          );
        })}

        <p className="subtitle">FALLBACK SLOT</p>
        {top3at2.map((item, index) => {
          return (
            <div className="count-metrics-cell" key={index}>
              <div className="title">{item[0]}</div>
              <div className="data">{item[1]}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MetricsStreamCount;
