import React from "react";
import { useStoreState } from "easy-peasy";
import getNumeral from "../../../utils/getNumeral";

const StreamCardMetrics = props => {
  const AllMetaData = useStoreState(state => state.streamMeta);

  const filtered = AllMetaData.filter(
    item => item.category === props.streamTitle
  );

  let meta = {
    "CTR%": "NA",
    CLICKS: "NA",
    IMPR: "NA",
    // "IMP/PST": "NA",
    VIEW_TIME: "NA",
    POSTS: "NA",
    USERS: "NA"
  };

  if (filtered.length !== 0) {
    meta = {
      "CTR%": filtered[0].CTR_percent
        ? filtered[0].CTR_percent.toFixed(2)
        : "NA",
      CLICKS: filtered[0].clicks,
      IMPR: filtered[0].impressions,
      // "IMP/PST": filtered[0].impr_per_post.toFixed(2),
      VIEW_TIME: filtered[0].avg_impr_time,
      POSTS: filtered[0].unique_posts,
      USERS: filtered[0].unique_users
    };
  }

  return (
    <div className="hover-matrix-stream">
      {Object.keys(meta).map((item, index) => (
        <div
          className="hover-matrix-stream-cell"
          style={{ color: item === "CTR%" ? "#15AB39" : "#000000" }}
          key={index}
        >
          <div className="data">{getNumeral(meta[item])}</div>
          <div className="title">{item}</div>
        </div>
      ))}
    </div>
  );
};

export default StreamCardMetrics;
