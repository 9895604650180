// module imports
import React, { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";

// utils and services import
import sortObjectOnTitle from "../../../utils/sortOptions";

// components imports
import { Icon, Modal, Dropdown, Button } from "semantic-ui-react";
import Stream from "./Stream";

const StreamList = styled.div`
  display: flex;
  margin: 1px 0;
  overflow-x: scroll;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  min-height: 45px;
`;

const Row = props => {
  const [modalOpen, toggleModal] = useState(false);
  const feedConfig = useStoreState(state => state.feedConfig);

  const openModal = () => {
    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
  };

  const handleDropdownSelection = (e, data) => {
    e.preventDefault();
    closeModal();
    props.addNewStream(props.row.id, data.value);
  };

  // Populate Dropdown
  const streams = Object.keys(feedConfig.config_data.streams);
  const options = [];
  streams.map(stream => {
    options.push({ key: stream, value: stream, text: stream });
    return 0;
  });
  options.sort(sortObjectOnTitle);
  // Populate Dropdown

  return (
    <div>
      <Modal open={modalOpen} size="small">
        <Modal.Header>Add New Stream</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Dropdown
              placeholder="Select Stream"
              selection
              fluid
              search
              options={options}
              onChange={handleDropdownSelection}
              selectOnNavigation={false}
              selectOnBlur={false}
            />
            <br />
            <Button onClick={closeModal}>Close</Button>
          </Modal.Description>
        </Modal.Content>
      </Modal>

      <div className="title">{props.row.title}</div>
      <Droppable droppableId={props.row.id} direction="horizontal">
        {(provided, snapshot) => (
          <StreamList
            className="no-scroll"
            style={{
              background: snapshot.isDraggingOver ? "#90caf9" : "",
              borderRadius: "5px"
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className="row-id">
              <Icon
                className="add-stream"
                name="angle double down"
                onClick={() => props.addNewRow(props.index)}
              />
              {props.index + 1}.
            </div>
            {props.streams.map((stream, index) => (
              <Stream key={stream.id} stream={stream} index={index} />
            ))}
            {provided.placeholder}
            <div className="add-stream" onClick={openModal}>
              <Icon name="plus" />
            </div>
          </StreamList>
        )}
      </Droppable>
    </div>
  );
};

export default Row;
