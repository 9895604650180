import numeral from "numeral";

const getNumeral = num => {
  if (num === "NA") {
    return num;
  } else {
    return numeral(num).format("0.0a");
  }
};

export default getNumeral;
