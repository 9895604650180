import React, { useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";
import getNumeral from "../../../utils/getNumeral";
import RoutingIcons from "./RoutingIcons";

const StreamPopup = props => {
  const [meta, setMeta] = useState({});
  const [metaLocal, setMetaLocal] = useState({});
  const AllMetaData = useStoreState(state => state.streamMeta);
  const AllLocalMetaData = useStoreState(state => state.streamMetaLocal);
  const selectedStream = useStoreState(
    state => state.currentDnd.selectedStream
  );

  useEffect(() => {
    const filtered = AllMetaData.filter(
      item => item.category === props.streamTitle
    );
    const filteredLocal = AllLocalMetaData.filter(
      item =>
        item.category === props.streamTitle &&
        item.setOrderKey === selectedStream
    );

    let metaTemp = {
      USERS: "NA",
      POSTS: "NA",
      IMPRESSIONS: "NA",
      "IMP PER POST": "NA",
      CLICKS: "NA",
      "CTR %": "NA"
    };

    let metaTempLocal = {
      USERS: "NA",
      POSTS: "NA",
      IMPRESSIONS: "NA",
      "IMP PER POST": "NA",
      CLICKS: "NA",
      "CTR %": "NA"
    };

    if (filtered.length !== 0) {
      metaTemp = {
        USERS: filtered[0].unique_users,
        POSTS: filtered[0].unique_posts,
        IMPRESSIONS: filtered[0].impressions,
        "IMP PER POST": filtered[0].impr_per_post.toFixed(2),
        CLICKS: filtered[0].clicks,
        "CTR %": filtered[0].CTR_percent.toFixed(2)
      };
    }

    if (filteredLocal.length !== 0) {
      metaTempLocal = {
        USERS: filteredLocal[0].unique_users,
        POSTS: filteredLocal[0].unique_posts,
        IMPRESSIONS: filteredLocal[0].impressions,
        "IMP PER POST": filteredLocal[0].impr_per_post.toFixed(2),
        CLICKS: filteredLocal[0].clicks,
        "CTR %": filteredLocal[0].CTR_percent.toFixed(2)
      };
    }

    setMeta(metaTemp);
    setMetaLocal(metaTempLocal);
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <strong>
        <RoutingIcons streamTitle={props.streamTitle} /> {props.streamTitle}
      </strong>
      <br />
      <br />
      <p>LOCAL PERFORMANCE</p>
      <div className="hover-matrix">
        {Object.keys(metaLocal).map((item, index) => (
          <div
            className="hover-matrix-cell"
            style={{ color: item === "CTR %" ? "#15AB39" : "" }}
            key={index}
          >
            <div className="data">{getNumeral(metaLocal[item])}</div>
            <div className="title">{item}</div>
          </div>
        ))}
      </div>
      <br />
      <p>GLOBAL PERFORMANCE</p>
      <div className="hover-matrix">
        {Object.keys(meta).map((item, index) => (
          <div
            className="hover-matrix-cell"
            style={{ color: item === "CTR %" ? "#15AB39" : "" }}
            key={index}
          >
            <div className="data">{getNumeral(meta[item])}</div>
            <div className="title">{item}</div>
          </div>
        ))}
      </div>
      <div className="popup-disclaimer">
        *based on measurement of last 7 days (Android Users)
      </div>
    </div>
  );
};

export default StreamPopup;
