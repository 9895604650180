import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";

const RoutingIcons = props => {
  return (
    <React.Fragment>
      <Popup
        trigger={
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://github.com/SheroesDev/sheroes-services/blob/integration/participant/src/main/java/com/sheroes/participant/feed/stream/impl/${props.streamTitle}.java`}
          >
            <Icon name="github" size="large" color="black" />
          </a>
        }
        content={"View Code On GitHub"}
        popperModifiers={{
          preventOverflow: { boundariesElement: "window" }
        }}
      />

      <Popup
        trigger={
          <Link to={`/studio/table?q=${props.streamTitle}`}>
            <Icon name="th list" size="large" />
          </Link>
        }
        content={"Open In Studio"}
        popperModifiers={{
          preventOverflow: { boundariesElement: "window" }
        }}
      />
    </React.Fragment>
  );
};

export default RoutingIcons;
