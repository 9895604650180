// module imports
import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Icon, Menu, Sidebar } from "semantic-ui-react";

const SidebarMenu = () => {
  const [visible, setVisible] = useState(false);

  const tabs = [
    { location: "setorder", displayName: "Set Order", icon: "server" },
    { location: "studio", displayName: "Studio", icon: "object group outline" }
  ];

  return (
    <React.Fragment>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        onHide={() => setVisible(false)}
        vertical
        visible={visible}
        direction="right"
      >
        {tabs.map((item, index) => {
          return (
            <Link to={item.location} key={index}>
              <Menu.Item as="div" onClick={() => setVisible(false)}>
                <Icon name={item.icon} size="small" />
                {item.displayName}
              </Menu.Item>
            </Link>
          );
        })}
      </Sidebar>

      <Icon
        style={{ cursor: "pointer" }}
        name="bars"
        size="large"
        onClick={() => {
          setVisible(true);
        }}
      />
    </React.Fragment>
  );
};
export default SidebarMenu;
