// module imports
import React, { useEffect, useState } from "react";

// utils and services import
import sortObjectOnTitle from "../../../utils/sortOptions";

// component imports
import { Dropdown, Form, Button } from "semantic-ui-react";
import CommitBox from "./CommitBox";
import { useStoreState } from "easy-peasy";
import Legend from "../Legend";
import NavTabs from "../navTabs";

const SelectStreamMenu = props => {
  const [options, setOptions] = useState([]);

  const feedConfig = useStoreState(state => state.feedConfig);
  const isDirty = useStoreState(state => state.isDirty);

  useEffect(() => {
    let optionHolder = [];
    const set_orders_streams = Object.keys(feedConfig.config_data.set_orders);
    set_orders_streams.map(stream => {
      optionHolder.push({ key: stream, value: stream, text: stream });
      return null;
    });
    optionHolder.sort(sortObjectOnTitle);
    setOptions(optionHolder);
    // eslint-disable-next-line
  }, []);

  const handleDropdownSelection = (e, data) => {
    e.preventDefault();
    props.selectStream(data.value);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <Form>
      <Form.Group>
        <Form.Field>
          <NavTabs />
        </Form.Field>
        <Form.Field>
          <Dropdown
            className="dropdown-setorder"
            placeholder="Select Set Order"
            selection
            fluid
            search
            options={options}
            onChange={handleDropdownSelection}
            selectOnNavigation={false}
            selectOnBlur={false}
            disabled={isDirty}
          />
        </Form.Field>
        <Form.Field>
          <CommitBox version={feedConfig.id} />
        </Form.Field>
        <Form.Field>
          {isDirty ? (
            <Button color="orange" onClick={reloadPage}>
              Reset All
            </Button>
          ) : null}
        </Form.Field>
        <Form.Field>
          <Legend />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default SelectStreamMenu;
