import React from "react";

import { categories } from "../../utils/legendValues";

const Legend = () => {
  return (
    <div className="legend">
      {categories.map(legend => {
        return (
          <div className="a-legend" key={legend[0]}>
            <div className="color" style={{ background: legend[1] }}></div>
            <div className="legend-name">
              {legend[0].charAt(0).toUpperCase() + legend[0].substring(1)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Legend;
