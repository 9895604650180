// module imports
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";

// component imports
import "./home.css";
import UserInfo from "./userInfo";

import StreamStudio from "./studio/StreamStudio";
import SetOrder from "./setorder/SetOrder";
import FourOFour from "../fourOfour.jsx/fourOfour";
import FeedInfo from "./statusbar/feedInfo";
import CommitDate from "./statusbar/commitDate";
import InfoMain from "./InfoPane/InfoMain";
import Filters from "./InfoPane/Filters";

const Home = props => {
  const setUser = useStoreActions(action => action.setUser);
  const feedConfig = useStoreState(state => state.feedConfig);

  setUser(props.user);

  return (
    <div>
      <div className="home-body">
        <div className="left-pane">
          <Switch>
            <Route path={"/setorder"} exact>
              <SetOrder />
            </Route>

            <Redirect path="/" to="/setorder" exact />

            <Route path={"/studio"}>
              <StreamStudio />
            </Route>

            <Route>
              <FourOFour />
            </Route>
          </Switch>
        </div>
        <div className="right-pane">
          <div className="header">
            <UserInfo />
          </div>
          <div className="content">
            <Route path={"/setorder"} exact>
              <InfoMain />
            </Route>
            <Route path={"/studio"}>
              <Filters />
            </Route>
          </div>
          <div className="footer">
            SHEROES LUMOS <sup>ALPHA</sup>
          </div>
        </div>
      </div>
      <div className="footer-status-bar">
        {Object.entries(feedConfig).length !== 0 ? (
          <React.Fragment>
            <FeedInfo />
            <CommitDate />
          </React.Fragment>
        ) : (
          "LOADING..."
        )}
      </div>
    </div>
  );
};

export default Home;
