import React from "react";
import { Icon } from "semantic-ui-react";
import { useStoreState } from "easy-peasy";

const CommitDate = () => {
  const feedConfig = useStoreState(state => state.feedConfig);
  return (
    <div>
      <Icon name="clock outline" color="green" />
      UPDATED ON |{" "}
      <strong>{new Date(feedConfig.created_on).toLocaleString()}</strong>
    </div>
  );
};

export default CommitDate;
