//module imports
import React from "react";

//components import
import { Loader } from "semantic-ui-react";
import "./splashscreen.css";

function Splashscreen() {
  return (
    <div className="splash-page">
      <div className="content">
        <p className="brand">SHEROES</p>
        <p className="title">LUMOS</p>
      </div>
      <div className="loader-bottom">
        <Loader active inline inverted></Loader>
        <div className=""> Initializing . . .</div>
      </div>
    </div>
  );
}

export default Splashscreen;
