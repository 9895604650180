// module imports
import React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";

// component imports
import { Label, Popup } from "semantic-ui-react";
import StreamPopup from "./StreamPopup";
import { categories } from "../../../utils/legendValues";
import { useStoreState } from "easy-peasy";

const AStream = styled.div`
  margin: 5px 0;
`;
const Stream = props => {
  const category = useStoreState(state =>
    state.feedConfig.config_data.streams[props.stream.content].categories
      ? state.feedConfig.config_data.streams[props.stream.content].categories[0]
      : "default"
  );

  const getColor = name => {
    var color = categories.filter(item => {
      return item[0] === name.toLowerCase();
    });
    return color[0][1];
  };

  return (
    <Draggable draggableId={props.stream.id} index={props.index}>
      {provided => (
        <AStream
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Popup
            position="right center"
            hoverable
            content={<StreamPopup streamTitle={props.stream.content} />}
            trigger={
              <Label
                style={{
                  borderLeft: "10px solid" + getColor(category)
                }}
                size="large"
                horizontal
                pointing={"right"}
              >
                {props.stream.content}
              </Label>
            }
            popperModifiers={{
              preventOverflow: { boundariesElement: "window" }
            }}
          ></Popup>
        </AStream>
      )}
    </Draggable>
  );
};

export default Stream;
