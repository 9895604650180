import React from "react";

import { useStoreState } from "easy-peasy";

import { Card } from "semantic-ui-react";
import StreamCard from "./StreamCard";
import useCategoryFilter from "../../../hooks/useCategoryFilter";
import useSearch from "../../../hooks/useSearch";

const StreamCardGroup = props => {
  const streams = useStoreState(state => state.feedConfig.config_data.streams);
  const categoryFilteredStreams = useCategoryFilter();
  const searched = useSearch(categoryFilteredStreams, props.search);

  var noCards = true;

  return (
    <Card.Group itemsPerRow={3}>
      {searched.map((name, index) => {
        if (searched.length !== 0) {
          noCards = false;
          return (
            <StreamCard
              key={index}
              name={name}
              id={index}
              meta={streams[name]}
              StreamCategory={
                streams[name].categories
                  ? streams[name].categories[0]
                  : "default"
              }
            />
          );
        } else {
          return null;
        }
      })}
      {noCards ? (
        <div>
          <br />
          No Cards to Display
        </div>
      ) : null}
    </Card.Group>
  );
};

export default StreamCardGroup;
