// module imports
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import cookie from "react-cookies";

import { StoreProvider, createStore } from "easy-peasy";

// services import
import setUserDetails from "./services/userDetails";
import model from "./model";

// components import
import Splashscreen from "./UI/splashscreen/splashscreen";
import Home from "./UI/home/home";
import Unauthorized from "./UI/unauthorized.jsx/Unauthorized";

const store = createStore(model);

const App = () => {
  const [loading, toggleLoading] = useState(true);
  const [userLocal, setUserLocal] = useState({});
  const [authorized, setAuthorized] = useState(0);
  // 0 - undecided, 1 - authorized, 2 - unauthorized

  const authToken = cookie.load(process.env.REACT_APP_COOKIE_KEY);

  useEffect(() => {
    if (authToken) {
      setUserDetails(authToken)
        .then(data => {
          if (data.emailId.endsWith("@sheroes.in")) {
            setUserLocal(data);
            setAuthorized(1);
          } else {
            setAuthorized(2);
          }
        })
        .catch(error => {
          setAuthorized(2);
        });
      toggleLoading(false);
    } else {
      window.location =
        process.env.REACT_APP_AUTH_SHEROES_URL +
        "/?redirectUrl=" +
        process.env.REACT_APP_AUTH_SHEROES_REDIRECT;
    }
    // eslint-disable-next-line
  }, [authorized]);

  if (loading) {
    return <Splashscreen />;
  } else {
    if (authorized === 1) {
      return (
        <StoreProvider store={store}>
          <BrowserRouter>
            <Switch>
              <Route path="/">
                <Home user={userLocal} />
              </Route>
            </Switch>
          </BrowserRouter>
        </StoreProvider>
      );
    } else if (authorized === 2) {
      return <Unauthorized />;
    }
  }
};

export default React.memo(App);
