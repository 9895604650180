import React from "react";
import { Icon } from "semantic-ui-react";
import { useStoreState } from "easy-peasy";

const FeedInfo = () => {
  const feedConfig = useStoreState(state => state.feedConfig);
  return (
    <div>
      <Icon name="check circle" color="green" />
      CURRENT CONFIG |{" "}
      <strong className="config-version">
        {feedConfig.config_version && "v"}
        {feedConfig.config_version}
      </strong>{" "}
      | {feedConfig.config_commit_message}
    </div>
  );
};

export default FeedInfo;
