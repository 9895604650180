import React, { useState, useEffect } from "react";
import { Input } from "semantic-ui-react";

const SearchBar = props => {
  const [text, setText] = useState("");

  const handleChange = e => {
    setText(e.target.value);
  };

  useEffect(() => {
    props.getSearch(text);
    //eslint-disable-next-line
  }, [text]);

  return (
    <Input icon="search" placeholder="Search..." onChange={handleChange} />
  );
};

export default SearchBar;
