import shortid from "shortid";
shortid.characters(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@"
);

const breakdownStream = (feedConfig, stream) => {
  const rows = {};
  const streams = {};
  const row_order = [];
  const stream_order = feedConfig.set_orders[stream]["stream_order"];

  for (let i = 0; i < stream_order.length; i++) {
    for (let j = 0; j < stream_order[i].length; j++) {
      const uuid = shortid.generate();
      streams[i.toString() + "-" + uuid] = {
        id: i.toString() + "-" + uuid,
        content: stream_order[i][j]
      };
    }
  }
  for (let i = 0; i < stream_order.length; i++) {
    rows[`row-${i}`] = {
      id: `row-${i}`,
      title: "",
      streamIds: Object.keys(streams).filter(id =>
        id.startsWith(i.toString() + "-")
      )
    };
    row_order.push(`row-${i}`);
  }

  return [rows, streams, row_order];
};

const generateConfigForAStream = (rows, streams, rowOrder) => {
  let streamOrder = [];
  rowOrder
    .filter(rowId => rows[rowId].streamIds.length !== 0)
    .map(rowId => {
      streamOrder.push(
        rows[rowId].streamIds.map(streamId => streams[streamId].content)
      );
      return null;
    });
  return streamOrder;
};

export { breakdownStream, generateConfigForAStream };
