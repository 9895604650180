import React from "react";
import { useStoreState } from "easy-peasy";

const JSONrender = () => {
  const clickedStream = useStoreState(state => state.clickedStream);
  const feedConfig = useStoreState(state => state.feedConfig);

  const data =
    clickedStream === ""
      ? [["info", "click view meta to show more info"]]
      : Object.entries(feedConfig.config_data.streams[clickedStream]);

  return (
    <div className="card-my">
      <h3 style={{ textOverflow: "ellipsis", wordBreak: "break-word" }}>
        {clickedStream}
      </h3>
      {data.map((items, index) => {
        return (
          <div className="meta-data" key={index}>
            <span className="title">{items[0]}</span>:
            <pre className="data" style={{ wordBreak: "break-word" }}>
              {JSON.stringify(items[1])}
            </pre>
          </div>
        );
      })}
    </div>
  );
};

export default JSONrender;
