import React, { useState, useEffect } from "react";
import { categories } from "../../../../utils/legendValues";
import { Checkbox } from "semantic-ui-react";
import { useStoreActions, useStoreState } from "easy-peasy";

const SelectCategory = props => {
  const [allCategories, setAllCategories] = useState([]);

  const stateFilters = useStoreState(state => state.filters);
  const updateFilters = useStoreActions(action => action.setFilters);

  const checkABox = (e, data) => {
    const value = data.value;
    const allCategoriesTemp = allCategories;
    if (data.checked === true) {
      setAllCategories([...allCategoriesTemp, data.value]);
    } else if (data.checked === false) {
      const index = allCategoriesTemp.indexOf(value);
      allCategoriesTemp.splice(index, 1);
      setAllCategories([...allCategoriesTemp]);
    }
  };

  useEffect(() => {
    updateFilters({ ...stateFilters, categories: allCategories });
    // eslint-disable-next-line
  }, [allCategories]);

  return (
    <div className="card-my">
      <div className="filter-heading">
        <h4 className="title">CATEGORIES </h4>
        <p className="subtitle">(select none to show all)</p>
      </div>
      {categories.map((category, index) => (
        <React.Fragment key={index}>
          <div
            className="color"
            style={{
              display: "inline-block",
              height: "10px",
              width: "10px",
              marginRight: "5px",
              backgroundColor: category[1]
            }}
          ></div>
          <Checkbox
            style={{ textTransform: "capitalize" }}
            label={category[0]}
            value={category[0]}
            onChange={checkABox}
          />
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

export default SelectCategory;
